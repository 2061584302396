<template>
  <div class="download_container">
      <el-row>
        <el-col :span="16" :offset="4">
          <p class="top_navigation"><span @click="$router.push('index')">首页</span> &gt; <span @click="changeNav(3)">技术服务</span> &gt; <span @click="changeNav(3)">软件服务</span> &gt; <span>软件下载</span></p>
        </el-col>
        <el-col :span="16" :offset="4">
          <p class="desc_text">软件下载主要提供公司不同类型产品的软件安装包，用户可根据实际情况下载使用，相关资料最终版本由磐石（南京）云计算有限公司最终提供解释。</p>
        </el-col>
        <el-col :span="16" :offset="4">
          <div class="title">
              <p>检索</p>
          </div>
        </el-col>
        <el-col :span="10" :offset="7">
          <!--    搜索框-->
          <div class="searchBox">
              <input type="text" v-model="searchInput" class="searchInput">
              <div class="searchIcon iconfont icon-sousuo1" @click="search">搜索</div>
          </div>
        </el-col>
        <el-col :span="16" :offset="4">
          <div class="title">
            <p>软件下载</p>
          </div>
        </el-col>
      </el-row>
      <!--    文档列表-->
      <el-row>
        <el-col :span="16" :offset="4">
          <div class="card">
            <p class="head">磐石云桌面</p>
            <div>
              <div class="listUl">
                <p @click="showVersion(0)">
                  <img src="@/assets/technicalSupport/domDownload/filedownload.png" />
                  <span href="#">标准版</span>
                </p>
                <p @click="showVersion(1)">
                  <img src="@/assets/technicalSupport/domDownload/filedownload.png" />
                  <span href="#">行业版</span>
                </p>
                <!-- <p @click="showVersion(2)">
                  <img src="@/assets/technicalSupport/domDownload/filedownload.png" />
                  <span href="#">豪华版</span>
                </p> -->
              </div>
              <!-- <div class="listUl">
                <p v-show="pansh.length == 0">暂无相关软件</p>
                <p v-for="(item,index) in pansh" :key="'a'+index" @click="$router.push('/PocSub')">
                  <img src="@/assets/technicalSupport/domDownload/filedownload.png" />
                  <span href="#">{{item.name}}</span>
                </p>
              </div> -->
            </div>
          </div>
          <div class="card">
            <p class="head">磐石客户端</p>
            <div>
              <div class="listUl">
                <p v-show="client.length == 0 && !showIOS">暂无相关软件</p>
                <p v-for="(item,index) in client" :key="index" @click="download(item.realName,item.id,item.downCount)">
                  <img src="@/assets/technicalSupport/domDownload/filedownload.png" />
                  <span href="#">{{item.name}}</span>
                </p>
                <p v-show="showIOS">
                  <router-link to="/downios" target="_blank">
                    <img src="@/assets/technicalSupport/domDownload/filedownload.png" />
                    <span>Acceptor For IOS</span>
                  </router-link>
                </p>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>

      <!-- 验证码对话框 -->
      <el-dialog class="veriDialog" title="请输入验证码" :visible.sync="verifyVisible" @closed="reactive_verify" :width="`25%`">
        <div class="verify_div">
          <input type="text" v-model="verify_num" placeholder="不区分大小写" class="verify_inp" />
          <Verify ref="Verify" class="verify_show"></Verify>
          <el-button type="primary" :disabled="verify_num.length != 4" @click="active_verify" class="verify_btn">提交</el-button>
          <div class="clearfix"></div>
        </div>
      </el-dialog>

      <!-- 版本选择弹框 -->
      <el-dialog title="请选择具体版本" :visible.sync="versionShow" width="30%">
        <div class="vera" v-for="(item,index) in showVerList" :key="'cc'+index">
          <p @click="toPoc(item)"><i class="el-icon-position"></i>{{ item.version }}</p>
          <div><i class="el-icon-info" @click="showMsgs(item)"></i></div>
        </div>
      </el-dialog>

      <!-- 升级详情展示 -->
      <el-drawer
        :title="remakTemp"
        :visible.sync="remarkShow"
        direction="rtl"
        size="40%">
        <div class="reDetail" v-html="remarkDetail"></div>
      </el-drawer>


  </div>
</template>

<script>
import {getPsDoc,postDownLoad,postDownLoadDount} from '@/api'
// import axios from 'axios';

export default {
    name: "softwareDownload",
    data() {
      return {
        // 搜索框
        searchInput: '',
        // 验证码输入框数据
        verify_num:'',
        // 文件名称
        fileName:'',
        // 云桌面列表
        // 磐石
        pansh:[],
        // 磐石（C）
        panshc:[],
        // DEMO
        demo:[],
        // 客户端列表
        client:[],
        // 是否显示ios
        showIOS:true,
        // 文件id
        fileid:'',
        // 验证码框显示
        verifyVisible:false,
        // 文件真实名称
        realName:'',
        /* 23.3.14修改 */
        bzs:[
        ],
        gjs:[
        ],
        hhs:[
        ],
        showVerList:[],
        versionShow:false,
        verTemp:'标准版',
        remakTemp:'',
        remarkShow:false,
        remarkDetail:'',
        downCount:0
      }
    },
    methods: {
      // 切换导航
      changeNav(navNum){
        this.$store.dispatch('changeShow',navNum)
      },
      toPoc(item){
        sessionStorage.setItem('reversion',item.version)
        this.$router.push('/PocSub')
        console.log('session',sessionStorage.getItem('reversion'))
        console.log('session',sessionStorage.getItem('remake'))
      },
      showVersion(code){
        if(code == 0){
          sessionStorage.setItem('remake','标准版 1.3.0')
          this.$router.push('/PocSub')
          /* if(this.bzs.length == 1){
            sessionStorage.setItem('reversion',this.bzs[0].version)
            this.$router.push('/PocSub')
          }else{
            this.showVerList = this.bzs
            this.versionShow = true
            this.verTemp = '标准版'
          } */
        }else if(code == 1){
          sessionStorage.setItem('remake','行业版 1.3.0')
          this.$router.push('/PocSub')
          /* if(this.gjs.length == 1){
            sessionStorage.setItem('reversion',this.gjs[0].version)
            this.$router.push('/PocSub')
          }else{
            this.showVerList = this.gjs
            this.versionShow = true
            this.verTemp = '高级版'
          } */
        }
        /* else if(code == 2){
          sessionStorage.setItem('remake','豪华版')
          if(this.hhs.length == 1){
            sessionStorage.setItem('reversion',this.hhs[0].version)
            this.$router.push('/PocSub')
          }else{
            this.showVerList = this.hhs
            this.versionShow = true
            this.verTemp = '豪华版'
          }
        } */
      },
      showMsgs(item){
        // console.log(item)
        this.remakTemp = item.version
        this.remarkDetail = item.remark
        this.remarkShow = true
      },
      // 获取文档下载列表
      async getPsDoc(){
        let result = await getPsDoc(this.searchInput)
        // 整理参数
        let panshList = []
        let panshcList = []
        let demoList = []
        let clientList = []
        // 根据type与cloudType区分类型
        result.data.filter(item => {
          if(item.type == 3){
            /* if(item.cloudType == 1){
              panshList.push(item)
            }else if(item.cloudType == 2){
              panshcList.push(item)
            }else if(item.cloudType == 3){
              demoList.push(item)
            } */
            if(item.name == '标准版'){
              panshList.push(item)
            }else if(item.name == '高级版'){
              panshcList.push(item)
            }else if(item.name == '豪华版'){
              demoList.push(item)
            }
          }else if(item.type == 4){
            clientList.push(item)
          }
          
        })
        // 赋值
        // this.pansh = panshList
        // this.panshc = panshcList
        // this.demo = demoList
        this.bzs = panshList
        this.gjs = panshcList
        this.hhs = demoList
        this.showVerList = panshList
        this.client = clientList
        // 清空数据
        panshList = []
        panshcList = []
        demoList = []
        clientList = []
      },
      // 搜索功能
      search(){
        // 判断是否包含ios项
        if('Acceptor For IOS'.indexOf(this.searchInput) == -1){
          this.showIOS = false
        }else{
          this.showIOS = true
        }
        this.getPsDoc()
      },

      // 判断是否需要验证
      verifyPhone(realName,id){
        // 存储phone，方便用户下次直接下载
        let ph = localStorage.getItem('phone');
        if(ph == null || ph == undefined || ph == ''){
          // 路由跳转并携带参数
          this.$router.push({name:'pocpage',params: {name:realName,id:id}})
        }else{
          // 整理参数下载文件
          let temp = {
            downloadId:id,
            phone:ph,
          }
          postDownLoad(temp)
          this.startDown(realName)

        }
      },
      // 下载功能
      download(realName,id,count){
        this.realName = realName
        this.fileid = id
        this.downCount = count
        this.verifyVisible = true
      },

      // 进行验证
      active_verify(){
        // 判断
        if(this.$refs.Verify.imgCode == this.verify_num.toUpperCase()){
          window.location.href=`${window.ipConfig.downloadUrl}api/down/download?filePath=upload//${this.realName}&fileName=${this.realName}`
          this.verifyVisible = false
          let temp = {
            downloadId:this.fileid,
          }
          let temp2 = {
            id:this.fileid,
            downCount:this.downCount
          }
          postDownLoad(temp)
          postDownLoadDount(temp2)
          this.verify_num = ''
          this.reactive_verify()
        }else{
          this.$message('验证码输入错误');
        }
      },
      // 下载功能
      startDown(realName){
        window.location.href=`${window.ipConfig.downloadUrl}api/down/download?filePath=upload//${realName}&fileName=${realName}`
        // 原先下载功能代码，不删除以备之后用到
        /* this.downloadDialogVisible = true
        let that = this
        axios({
          method:'post',
          url:'/api/down/download',
          data:{  
            url:'C:\\panshiWeb\\upload\\' + realName
          },
          responseType:'blob',
          transformRequest: [function (data) {
            let ret = ''
            for (let it in data) {
              ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
            }
            return ret
          }],
          // 进度条
          onDownloadProgress: function (progressEvent) {//axios封装的原生获取下载进度的事件，该回调参数progressEvent中包含下载文件的总进度以及当前进度
          if (progressEvent.lengthComputable) {
              that.percentage = Math.floor(progressEvent.loaded / progressEvent.total * 100)  //实时获取最新下载进度
              console.log(progressEvent.loaded / progressEvent.total * 100)
            }
          }
          
        })
        .then(res => {
          const content = res.data
          const blob = new Blob([content])
          if ('download' in document.createElement('a')) {
            const elink = document.createElement('a')
            elink.download = realName
            elink.style.display = 'none'
            elink.href = URL.createObjectURL(blob)
            document.body.appendChild(elink)
            elink.click()
            URL.revokeObjectURL(elink.href)
            document.body.removeChild(elink)
          } else {
            navigator.msSaveBlob(blob,realName)
          }
          setTimeout(() => {
            this.downloadDialogVisible = false
            this.percentage = 0
          }, 5000);
        }) */
      },
      // 验证图形验证码
      reactive_verify(){
        this.verify_num = ''
        this.$refs.Verify.handleDraw()
      },
      

    },
    computed:{
      window: function () {
        // `window` 指向 window 
        return window
      }
    },
    mounted() {
      // 获取数据
      this.getPsDoc()
    },

}
</script>

<style scoped>
/* 通用样式*/
li {
    list-style: none;
}

a {
    color: #496AB1;
    text-decoration: none;
}
/* 导航栏 */
.top_navigation{
  margin-top: 20px;
}
.top_navigation span{
  font-size: 16px;
  color: #666;
  cursor: pointer;
  transition: all 0.3s;
}
.top_navigation span:hover{
  color: #e40f0f;
}
/*页面样式*/
.reDetail{padding: 0 20px;font-size: 16px;}
.vera{display: flex;margin-bottom: 15px;justify-content: space-between}
.vera p{font-size: 15px;color: #333;cursor: pointer;}
.vera p i{margin-right: 15px;}
.vera > div{margin-left: 20px;cursor: pointer;}
.download_container{
  padding-bottom: 50px;
}
>>> .veriDialog .el-dialog{min-width: 480px;}
/* 验证码 */
.verify_inp{
  float: left;
  line-height: 38px;
  border: 1px solid #409EFF;
  border-radius: 5px;
  margin-right: 10px;
  font-size: 16px;
  padding-left: 10px;
}
.verify_show{
  float: left;
  margin-right: 10px;
  cursor: pointer;
}
.verify_btn{
  float: left;
}
.clearfix{
  clear: both;
}
/* 标题 */
.desc_text{
  font-size: 16px;
  color: #666666;
  margin-top: 30px;
  margin-bottom: 40px;
}
.title{
  margin-top: 30px;
  margin-bottom: 30px;
}
.title p{
  border-left: 6px solid #e40f0f;
  line-height: 50px;
  font-size: 32px;
  padding-left: 30px;

}
/*搜索框*/
.searchBox {
    width: 100%;
    text-align: right;
    margin: 1% 0 3% 0;
    position: relative;
}

.searchInput {
    padding: 0;
    width: 96%;
    border: 1px solid #cacaca;
    border-radius: 0px;
    line-height: 46px;
    padding-left: 10px;
}

.searchIcon {
  padding: 0;
  cursor: pointer;
  border: 0;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 18px;
  width: 16%;
  text-align: center;
  line-height: 48px;
  color: #fff;
  background: #e40f0f;
}
.searchIcon::before{
  margin-right: 10px;
}
.clearfix{clear: both;}

/*文档列表*/
.card{
  margin-bottom: 30px;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  border: 1px solid #EBEEF5;
  border-radius: 4px;
  overflow: hidden;
}
.head{
  background: #f2f2f2;
  padding: 10px 20px;
  font-size: 22px;
  font-weight: 500;
}
.listTitle {
  line-height: 48px;
  font-size: 32px;
  color: #101010;
}
.tit{
  font-size: 18px;
  margin-top: 10px;
}
.listUl{
  padding: 20px;
}
.listUl p {
  display: inline-block;
  width: 33.33%;
  font-size: 18px;
  line-height: 30px;
  color: #496AB1;
  margin: 0.5% 0;
  cursor: pointer;
}
.listUl p img{
  margin-right: 5px;
  position: relative;
  top: 3px;
}
.listUl p span{
  font-size: 16px;
  line-height: 30px;
}

/* 抽屉文档列表样式 */
.listUld{
  width: 80%;
  margin:0 auto;

}
.listUld p {
  font-size: 18px;
  line-height: 30px;
  color: #496AB1;
  margin: 0.5% 0;
  cursor: pointer;
}
.listUld p img{
  margin-right: 5px;
  position: relative;
  top: 3px;
}
.listUld p span{
  font-size: 16px;
  line-height: 40px;
}
/* 验证码 */
.clearfix{
  clear: both;
}
</style>